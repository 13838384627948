import { richText, stripRichTextWrapperTag } from '~/utils/html';
import { adapter as imageAdapter } from '~/components/cloud-image/utils';
import { routerLinks } from '~/utils/fetch-links';

const sanitizeData = (entry, seoLevel) => {
  return {
    primaryColor: entry.primary_color,
    label: entry.banner_label,
    title: stripRichTextWrapperTag(entry.banner_title),
    titleTag: `h${seoLevel + 1}`,
    text: richText(entry.banner_description),
    imageMobile: imageAdapter(
      entry.banner_image_mobile,
      entry.banner_image_alt
    ),
    imageDesktop: imageAdapter(
      entry.banner_image_desktop,
      entry.banner_image_alt
    ),
  };
};

const getItemsWithLinks = (banner, items, $enhancedLinkSerializer) => {
  if (banner && banner?.data?.banner_entries) {
    banner.data.banner_entries.forEach((entry, index) => {
      if (entry.banner_link) {
        if (entry.banner_link_rel) {
          entry.banner_link.rel = entry.banner_link_rel;
        }

        items[index].cta.link = $enhancedLinkSerializer(entry.banner_link);
      }
    });
  }
  return items;
};

const getItems = async (
  moduleData,
  moduleId,
  $prismic,
  $enhancedLinkSerializer,
  seoLevel,
  i18n
) => {
  let needfetch = false;

  const listItems = moduleData?.banner_entries.map((entry) => {
    const sanitized = sanitizeData(entry, seoLevel);

    if (entry.banner_link) {
      // Check if link is using market or product to force module fetch
      if (entry.banner_link.link_type === 'Document') {
        needfetch = true;
      }

      if (entry.banner_link_rel) {
        entry.banner_link.rel = entry.banner_link_rel;
      }

      sanitized.cta = {
        label: entry.banner_link_label || i18n.t('cta.en-savoir-plus'),
        link: $enhancedLinkSerializer(entry.banner_link),
      };
    }

    return sanitized;
  });

  let items = listItems;
  // Fetch module again to have all the needed data to generate links
  if (needfetch) {
    const banner = await $prismic.client.getByID(moduleId, {
      fetchLinks: routerLinks,
    });
    items = getItemsWithLinks(banner, items, $enhancedLinkSerializer);
  }

  return items;
};

export default async (
  { primary },
  data,
  { $i18n, $prismic, $enhancedLinkSerializer },
  { seoLevel }
) => {
  const defaultModule = primary.banner_default;
  const defaultModuleData = defaultModule?.data;

  if (!defaultModuleData) return null;

  const defaultModuleProps = {
    title: stripRichTextWrapperTag(defaultModuleData?.sectionTitle),
    narrow: defaultModuleData?.is_narrow,
    items: defaultModuleData
      ? await getItems(
          defaultModuleData,
          defaultModule.id,
          $prismic,
          $enhancedLinkSerializer,
          seoLevel,
          $i18n
        )
      : [],
  };

  return defaultModuleProps;
};
